body {
  background-color: rgb(43, 41, 44);
}

.App {
  text-align: center;
}

.App-logo {
  margin-top: 20px;
  height: 28vmin;
  max-height: 260px;
  pointer-events: none;
  border-radius: 2vh;
}

.App-title {
  margin-top: 1vh;
  margin-bottom: 0.5vh;
  font-weight: 300;
  font-size: calc(40px + 2vmin);
}

.App-title-subtext {
  margin-top: 0px;
  margin-bottom: 3vh;
  font-weight: 300;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow: hidden;
}

.footer {
  position: absolute;
  bottom: 0;
  font-size: calc(4px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.Section {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.SectionContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-inline-end: 40px;
  margin-bottom: 40px;
}

.app-images {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80vw;
}

.app-image-container {
}

.app-image {
  width: 100%;
}

@media all and (max-width: 800px) {
  .app-images {
    flex-wrap: wrap;
  }
}
